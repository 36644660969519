import { safeShortcuts as request } from '@myua/api';

export type ServicesInfo = {
  groups: ServiceGroup[];
  favorites: ServiceFavorite[];
};

export type ServiceGroup = { id: number; name: string; items: ServiceItem[] };

export type ServiceItem = {
  key: string;
  name: string;
  url: string;
  submenu_url: string | null;
  icon: string | null;
  hc_category_id: string;
  status: string;
  show_in_menu: boolean | null;
  short_description: string | null;
};

export enum ServiceItemStatus {
  enabled = 'enabled',
  disabled = 'disabled',
  development = 'development',
}

export type ServiceFavorite = {
  key: string;
  created_date: string;
};

export const loadServicesInfo = () =>
  request.get<ServicesInfo>({ url: '/api/core/services/info/' });

export const createFavorite = (key: ServiceFavorite['key']) =>
  request.post<ServiceFavorite>({ url: 'api/core/services/favorites/', payload: { key } });

export const deleteFavorite = (key: ServiceFavorite['key']) =>
  request.delete({ url: `api/core/services/favorites/${key}/` });
